import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Project from "../components/project"

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectsQuery {
        allMdx(sort: { fields: frontmatter___title, order: ASC }) {
          nodes {
            id
            body
            frontmatter {
              project
              projectID
              projectURL
              projectDate(formatString: "YYYY/MM/DD")
            }
          }
        }
      }
    `}
    render={data => (
      <section id="projects">
        <div className="container mx-auto">
          <div className="px-8 pb-32">
            <h2 className="mb-16 text-center">Web Development Projects</h2>

            <Project projects={data.allMdx.nodes} id="easytithe" />
            <Project projects={data.allMdx.nodes} id="givinghelpdesk" />
            <Project projects={data.allMdx.nodes} id="kindrid" />
            <Project projects={data.allMdx.nodes} id="mogiv" />
            <Project projects={data.allMdx.nodes} id="ministrylinq" />
            <Project projects={data.allMdx.nodes} id="shelbysystems" />
            <Project projects={data.allMdx.nodes} id="easytitheblog" />
            <Project projects={data.allMdx.nodes} id="allamericancalendars" />
            <Project projects={data.allMdx.nodes} id="graingertnhistory" />
          </div>
        </div>
      </section>
    )}
  />
)

import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import SecAbout from "../sections/about"
import SecProjects from "../sections/projects"
import SecUses from "../sections/uses"

const HomePage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thomas Daugherty, UI/UX Web Developer from Knoxville, TN</title>
        <link rel="canonical" href="https://thomasdaugherty.com/" />
      </Helmet>

      <SecAbout />
      <SecProjects />
      <SecUses />
    </Layout>
  )
}

export default HomePage

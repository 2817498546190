import React from "react"

import IconGatsby from "../static/images/icons/dev/framework/gatsby.svg"
import IconReact from "../static/images/icons/dev/framework/react-js.svg"
import IconTailwind from "../static/images/icons/dev/processing/tailwindcss.svg"
import IconNetlify from "../static/images/icons/dev/hosting/netlify.svg"
// import IconGithub from "../static/images/icons/dev/git/github.svg"

var today = new Date()
var year = today.getFullYear()
const expYears = year - 2008

export default () => (
  <section id="about" className="px-8 py-32">
    <div className="container mx-auto relative z-10">
      <div className="max-w-xl">
        <div className="font-medium text-lg mb-16">
          <h1 className="text-3xl">Hello, my name is Thomas Daugherty!</h1>
          <p>
            Passionate in providing only the best user experience in Web
            Development and building Gunpla's on the side.
          </p>
          <p>
            I began my career in Graphic Designer after graduating from
            Tennessee Technology Center. Shortly after began tinkering with Web
            Development with WordPress and decided this was my next career path.
            I've since continued to educate myself online to learn Web
            Development. With the rise of Serverless Web Development and PWA
            Apps, I felt the need to begin another career path!
          </p>
          <p>
            I'm a UI/UX Web Developer based in Knoxville, TN, with a combined
            total of {expYears} years of experience in both Graphic Design and
            Web Development. I've used my skill set to help grow small
            businesses to large scale SaaS companies. Currently, I'm focused on
            building with React, VueJS, GatsbyJS, and Next.JS with a heavily
            focused on UI/UX layout design. Reach out if you're interested in
            hiring me!
          </p>
        </div>
      </div>
    </div>
  </section>
)

import React from "react"

// import Git from "../static/images/icons/dev/git/git.svg"
import AffinityDesigner from "../static/images/icons/design/affinitydesigner.svg"
import AfterEffects from "../static/images/icons/design/adobeaftereffects.svg"
import Asana from "../static/images/icons/office/asana.svg"
import AzureDevOps from "../static/images/icons/dev/hosting/azuredevops.svg"
import Babel from "../static/images/icons/dev/processing/babel.svg"
import Basecamp from "../static/images/icons/office/basecamp.svg"
// import Bitbucket from "../static/images/icons/dev/git/bitbucket.svg"
import Blender from "../static/images/icons/design/blender.svg"
import Bulma from "../static/images/icons/dev/other/bulma.svg"
import CSS from "../static/images/icons/dev/language/css3.svg"
import DigitalOcean from "../static/images/icons/dev/hosting/digitalocean.svg"
import Docker from "../static/images/icons/dev/other/docker.svg"
import Dynamics365 from "../static/images/icons/office/dynamics365.svg"
import ESLint from "../static/images/icons/dev/processing/eslint.svg"
import Figma from "../static/images/icons/design/figma.svg"
import Firebase from "../static/images/icons/dev/api/firebase.svg"
import Gatsby from "../static/images/icons/dev/framework/gatsby.svg"
// import Github from "../static/images/icons/dev/git/github.svg"
import GoogleAnalytics from "../static/images/icons/dev/other/googleanalytics.svg"
import GoogleMaps from "../static/images/icons/dev/api/googlemaps.svg"
import Graphql from "../static/images/icons/dev/language/graphql.svg"
import Grunt from "../static/images/icons/dev/processing/grunt.svg"
import Gulp from "../static/images/icons/dev/processing/gulp.svg"
import Html5 from "../static/images/icons/dev/language/html5.svg"
import Hubspot from "../static/images/icons/office/hubspot.svg"
import Illustrator from "../static/images/icons/design/adobeillustrator.svg"
import Javascript from "../static/images/icons/dev/language/javascript.svg"
import JQuery from "../static/images/icons/dev/framework/jquery.svg"
import JSON from "../static/images/icons/dev/language/json.svg"
import Markdown from "../static/images/icons/dev/language/markdown.svg"
import MongoDB from "../static/images/icons/dev/database/mongodb.svg"
import MySQL from "../static/images/icons/dev/database/mysql.svg"
import Netlify from "../static/images/icons/dev/hosting/netlify.svg"
import Nextjs from "../static/images/icons/dev/framework/next-dot-js.svg"
import NodeJs from "../static/images/icons/dev/language/node-dot-js.svg"
// import NPM from "../static/images/icons/dev/other/npm.svg"
import Notion from "../static/images/icons/office/notion.svg"
import PayPal from "../static/images/icons/dev/api/paypal.svg"
import Photoshop from "../static/images/icons/design/adobephotoshop.svg"
import PHP from "../static/images/icons/dev/language/php.svg"
import PostCSS from "../static/images/icons/dev/processing/postcss.svg"
import Postman from "../static/images/icons/dev/other/postman.svg"
import Premiere from "../static/images/icons/design/adobepremiere.svg"
import Prettier from "../static/images/icons/dev/processing/prettier.svg"
import ReactJS from "../static/images/icons/dev/framework/react-js.svg"
import Salesforce from "../static/images/icons/office/salesforce.svg"
import Sass from "../static/images/icons/dev/framework/sass.svg"
import Sentry from "../static/images/icons/dev/other/sentry.svg"
import Sketch from "../static/images/icons/design/sketch.svg"
import Slack from "../static/images/icons/office/slack.svg"
import Storybook from "../static/images/icons/dev/other/storybook.svg"
import Stripe from "../static/images/icons/dev/api/stripe.svg"
import StyledComponents from "../static/images/icons/dev/processing/styled-components.svg"
import Svelte from "../static/images/icons/dev/framework/svelte.svg"
import Tailwindcss from "../static/images/icons/dev/processing/tailwindcss.svg"
import Vercel from "../static/images/icons/dev/hosting/vercel.svg"
import Visualstudiocode from "../static/images/icons/dev/other/visualstudiocode.svg"
// import Vue from "../static/images/icons/dev/framework/vue-dot-js.svg"
import Webpack from "../static/images/icons/dev/processing/webpack.svg"
import Wordpress from "../static/images/icons/dev/framework/wordpress.svg"
import WPEngine from "../static/images/icons/dev/hosting/wpengine.svg"

export default () => (
  <section className="py-32 px-8 bg-cove-500 text-white text-center">
    <div className="container mx-auto">
      <div className="pb-16 max-w-lg mx-auto">
        <h2>Uses</h2>
        <p>
          Over the years I've built highly optimized, user-focused, and large
          scale SaaS websites. These are the various technologies, frameworks,
          and all the fantastic tools that I've used since.
        </p>
      </div>

      <div className="dev-icons">
        <h3>Language's</h3>

        <ul>
          <li>
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#E34F26" }}
            >
              <Html5 />
              <span>HTML5</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.php.net"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#777BB4" }}
            >
              <PHP />
              <span>PHP</span>
            </a>
          </li>
          <li>
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/CSS/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#1572B6" }}
            >
              <CSS />
              <span>CSS</span>
            </a>
          </li>
          <li>
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/javascript/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#F7DF1E" }}
            >
              <Javascript />
              <span>Javascript</span>
            </a>
          </li>
          <li>
            <a
              href="https://nodejs.org/en/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#339933" }}
            >
              <NodeJs />
              <span>Node.js</span>
            </a>
          </li>
          <li>
            <a
              href="https://graphql.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#E10098" }}
            >
              <Graphql />
              <span>GraphQL</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.json.org/json-en.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000" }}
            >
              <JSON />
              <span>JSON</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.markdownguide.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000" }}
            >
              <Markdown />
              <span>Markdown</span>
            </a>
          </li>
        </ul>

        <br />

        <h3>Framework's</h3>
        <ul>
          <li>
            <a
              href="https://wordpress.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#21759B" }}
            >
              <Wordpress />
              <span>Wordpress</span>
            </a>
          </li>
          <li>
            <a
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#61DAFB" }}
            >
              <ReactJS />
              <span>React</span>
            </a>
          </li>
          {/* <li>
              <a href="https://vuejs.org" target="_blank" rel="noopener noreferrer" style={{ color: "#4FC08D" }}>
                <Vue />
                <span>Vue.js</span>
              </a>
            </li> */}
          <li>
            <a
              href="https://www.gatsbyjs.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#663399" }}
            >
              <Gatsby />
              <span>Gatsby</span>
            </a>
          </li>
          <li>
            <a
              href="https://nextjs.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000" }}
            >
              <Nextjs />
              <span>NEXT.js</span>
            </a>
          </li>
          <li>
            <a
              href="https://svelte.dev"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FF3E00" }}
            >
              <Svelte />
              <span>SVELTE</span>
            </a>
          </li>
          <li>
            <a
              href="https://jquery.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#0769AD" }}
            >
              <JQuery />
              <span>JQuery</span>
            </a>
          </li>
          <li>
            <a
              href="https://sass-lang.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#CC6699" }}
            >
              <Sass />
              <span>Sass</span>
            </a>
          </li>
        </ul>

        <br />

        <h3>Build Tools</h3>
        <ul>
          <li>
            <a
              href="https://webpack.js.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#8DD6F9" }}
            >
              <Webpack />
              <span>Webpack</span>
            </a>
          </li>
          <li>
            <a
              href="https://gulpjs.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#DA4648" }}
            >
              <Gulp />
              <span>Gulp</span>
            </a>
          </li>
          <li>
            <a
              href="https://gruntjs.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FBA919" }}
            >
              <Grunt />
              <span>Grunt</span>
            </a>
          </li>
          <li>
            <a
              href="https://babeljs.io"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#F9DC3E" }}
            >
              <Babel />
              <span>Babel</span>
            </a>
          </li>
          <li>
            <a
              href="https://eslint.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#4B32C3" }}
            >
              <ESLint />
              <span>ESLint</span>
            </a>
          </li>
          <li>
            <a
              href="https://postcss.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#DD3A0A" }}
            >
              <PostCSS />
              <span>PostCSS</span>
            </a>
          </li>
          <li>
            <a
              href="https://prettier.io"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#F7B93E" }}
            >
              <Prettier />
              <span>Prettier</span>
            </a>
          </li>
        </ul>

        <br />

        <h3>Hosting</h3>
        <ul>
          <li>
            <a
              href="https://www.netlify.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00C7B7" }}
            >
              <Netlify />
              <span>Netlify</span>
            </a>
          </li>
          <li>
            <a
              href="https://vercel.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000" }}
            >
              <Vercel />
              <span>Vercel</span>
            </a>
          </li>
          <li>
            <a
              href="https://wpengine.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#40BAC8" }}
            >
              <WPEngine />
              <span>WP Engine</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.digitalocean.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#0080FF" }}
            >
              <DigitalOcean />
              <span>DigitalOcean</span>
            </a>
          </li>
          <li>
            <a
              href="https://azure.microsoft.com/en-us/services/devops/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#0078D7" }}
            >
              <AzureDevOps />
              <span>Azure DevOps</span>
            </a>
          </li>
        </ul>

        <br />

        <h3>API's</h3>
        <ul>
          <li>
            <a
              href="https://cloud.google.com/maps-platform/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#4285F4" }}
            >
              <GoogleMaps />
              <span>Google Maps</span>
            </a>
          </li>
          <li>
            <a
              href="https://developer.paypal.com/classic-home/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00457C" }}
            >
              <PayPal />
              <span>PayPal</span>
            </a>
          </li>
          <li>
            <a
              href="https://stripe.com/docs/api/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#008CDD" }}
            >
              <Stripe />
              <span>Stripe</span>
            </a>
          </li>
        </ul>

        <br />

        <h3>Other</h3>
        <ul>
          <li>
            <a
              href="https://code.visualstudio.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007ACC" }}
            >
              <Visualstudiocode />
              <span>VS Code</span>
            </a>
          </li>
          {/* <li>
              <a
                href="https://www.npmjs.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#CB3837" }}
              >
                <NPM />
                <span>NPM</span>
              </a>
            </li> */}
          {/* <li>
              <a
                href="https://github.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#181717" }}
              >
                <Github />
                <span>Github</span>
              </a>
            </li> */}
          {/* <li>
              <a
                href="https://bitbucket.org"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#0052CC" }}
              >
                <Bitbucket />
                <span>Bitbucket</span>
              </a>
            </li> */}
          <li>
            <a
              href="https://styled-components.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#DB7093" }}
            >
              <StyledComponents />
              <span>styled-components</span>
            </a>
          </li>
          <li>
            <a
              href="https://tailwindcss.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#38B2AC" }}
            >
              <Tailwindcss />
              <span>Tailwind CSS</span>
            </a>
          </li>
          <li>
            <a
              href="https://bulma.io"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00D1B2" }}
            >
              <Bulma />
              <span>Bulma</span>
            </a>
          </li>
          <li>
            <a
              href="https://storybook.js.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FF4785" }}
            >
              <Storybook />
              <span>Storybook</span>
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FFCA28" }}
            >
              <Firebase />
              <span>Firebase</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.mongodb.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#47A248" }}
            >
              <MongoDB />
              <span>MongoDB</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.mysql.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#4479A1" }}
            >
              <MySQL />
              <span>MySQL</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.docker.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#2496ED" }}
            >
              <Docker />
              <span>Docker</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.postman.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "##FF6C37" }}
            >
              <Postman />
              <span>Postman</span>
            </a>
          </li>
          <li>
            <a
              href="https://sentry.io"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FB4226" }}
            >
              <Sentry />
              <span>Sentry</span>
            </a>
          </li>
          <li>
            <a
              href="https://analytics.google.com/analytics/web/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#E37400" }}
            >
              <GoogleAnalytics />
              <span>Analytics</span>
            </a>
          </li>
        </ul>

        <br />

        <h3>Graphic Design</h3>
        <ul>
          <li>
            <a
              href="https://www.figma.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#F24E1E" }}
            >
              <Figma />
              <span>Figma</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.sketch.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#F7B500" }}
            >
              <Sketch />
              <span>Sketch</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.adobe.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FF7C00" }}
            >
              <Illustrator />
              <span>Illustrator</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.adobe.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#26C9FF" }}
            >
              <Photoshop />
              <span>Photoshop</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.adobe.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#EA77FF" }}
            >
              <Premiere />
              <span>Premiere</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.adobe.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#D291FF" }}
            >
              <AfterEffects />
              <span>After Effects</span>
            </a>
          </li>
          <li>
            <a
              href="https://affinity.serif.com/en-gb/designer/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#1B72BE" }}
            >
              <AffinityDesigner />
              <span>Affinity Designer</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.blender.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#F5792A" }}
            >
              <Blender />
              <span>Blender</span>
            </a>
          </li>
        </ul>

        <br />

        <h3>Office</h3>
        <ul>
          <li>
            <a
              href="https://slack.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#4A154B" }}
            >
              <Slack />
              <span>Slack</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.notion.so"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#000000" }}
            >
              <Notion />
              <span>Notion</span>
            </a>
          </li>
          <li>
            <a
              href="https://asana.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#273347" }}
            >
              <Asana />
              <span>Asana</span>
            </a>
          </li>
          <li>
            <a
              href="https://basecamp.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#5ECC62" }}
            >
              <Basecamp />
              <span>Basecamp</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.hubspot.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FF7A59" }}
            >
              <Hubspot />
              <span>HubSpot</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.salesforce.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#00A1E0" }}
            >
              <Salesforce />
              <span>Salesforce</span>
            </a>
          </li>
          <li>
            <a
              href="https://dynamics.microsoft.com/en-us/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#002050" }}
            >
              <Dynamics365 />
              <span>Dynamics 365</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

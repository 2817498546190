import React from "react"
// import { Link } from "gatsby"
// import FsLightbox from "fslightbox-react"

// import { Actions } from "./actions"

const Gallery = props => {
  // console.log(props)
  return (
    <div className="project-gallery">
      <ul className="grid gap-8 grid-row-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {props.children.map((child, index) => {
          return (
            <li key={index}>
              <div className="bg-white border-8 border-alto-200 overflow-hidden">
                {child}
              </div>

              {/* <Actions /> */}
            </li>
          )
        })}
      </ul>

      {/* <FsLightbox toggler={fs} sources={props.children} /> */}
    </div>
  )
}

export default Gallery

import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const GalleryImg = props => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          internal: { mediaType: { regex: "/image/" } }
          dir: { regex: "/gallery/" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  function findMatch(e) {
    const relativePath = e.node.relativePath

    if (relativePath.includes(props.src)) {
      return e.node
    }
  }
  const match = data.allFile.edges.find(findMatch)
  const img = useMemo(() => match, [match])

  return (
    <div>
      {img ? (
        <Img fluid={img.node.childImageSharp.fluid} alt={props.alt} />
      ) : (
        <div
          className="flex justify-between align-middle p-4 bg-red-100 text-red-500"
          role="alert"
        >
          <span className="font-bold text-center mr-2">Image Not Found</span>
          <i class="ri-alert-fill"></i>
        </div>
      )}
    </div>
  )
}

export default GalleryImg

import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Gallery from "./gallery/gallery"
import GalleryVid from "./gallery/vid"
import GalleryImg from "./gallery/img"

const shortcodes = { Gallery, GalleryVid, GalleryImg }

const Project = props => {
  let query = props.projects.filter(function(project) {
    return project.frontmatter.projectID === props.id
  })
  const project = query[0]

  return (
    <article
      key={`project-${project.frontmatter.projectID}`}
      id={`project-${project.frontmatter.projectID}`}
      className="mb-32 border-8 border-alto-200 rounded-lg"
    >
      <div className="flex flex-wrap justify-between items-end mx-8 pt-12 pb-8 border-b-8 border-alto-200">
        <div className="flex-initial">
          <h3 className="leading-none mb-2 text-4xl">
            <a
              href={`#project-${project.frontmatter.projectID}`}
              rel="noopener noreferrer"
              className="text-cove-100 no-underline"
            >
              #
            </a>{" "}
            {project.frontmatter.project}
          </h3>

          <p className="mb-0">
            <a
              href={project.frontmatter.projectURL}
              rel="noopener noreferrer"
              target="_blank"
              className="inline-block"
            >
              {" "}
              {project.frontmatter.projectURL}
            </a>{" "}
            <i className="ri-external-link-fill"></i>
          </p>
        </div>
        <div className="flex-initial w-full sm:w-auto">
          <p className="text-sm font-bold text-cove-300 mb-0">
            Posted, {project.frontmatter.projectDate}
          </p>
        </div>
      </div>
      <div className="p-8">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{project.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </article>
  )
}

export default Project

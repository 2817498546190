import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

const GalleryVid = props => {
  const root =
    "https://res.cloudinary.com/z33/video/upload/w_700,h_512/v1585844374/gallery/"

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          internal: { mediaType: { regex: "/image/" } }
          dir: { regex: "/gallery/" }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  function findMatch(e) {
    const relativePath = e.node.relativePath

    if (relativePath.includes(props.file)) {
      return e.node
    }
  }

  const match = data.allFile.edges.find(findMatch)
  const poster = useMemo(() => match, [match])

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "73.142%",
        height: 0,
      }}
    >
      <video
        muted
        controls
        disablePictureInPicture
        loop
        poster={poster ? poster.node.childImageSharp.fluid.src : ""}
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <source src={root + props.file + `.mp4`} type="video/mp4" />
        <source src={root + props.file + `.webm`} type="video/webm" />
        <source src={root + props.file + `.ogg`} type="video/ogg" />
      </video>
    </div>
  )
}

export default GalleryVid
